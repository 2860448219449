<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <div class="column is-5-desktop is-8-tablet">
            <div class="card px-6 py-4">
              <div class="card-content">
                <div class="block has-text-centered">
                  <img class="card_logo" src="@/assets/logo-microanalisis-dark.png" alt="Logo Microanalisis">
                </div>
                <div class="block">
                  <h1 class="title_component has-text-centered">RECUPERAR CONTRASEÑA</h1>
                </div>
                <div class="block mb-6">
                  <p class="paragraph has-text-centered">
                    Se enviará un enlace para recuperar <br>
                    la contraseña
                  </p>
                </div>
                <form @submit.prevent="forgotPassword" class="columns is-multiline">
                  <div class="column is-12">
                    <div class="field control">
                      <label class="label" for="email">
                        Correo electrónico
                      </label>
                      <input
                        id="email"
                        class="input"
                        type="text"
                        name="email"
                        v-model="email"
                        placeholder="correo@ejemplo.mx"
                        v-validate="'email|required'"
                        data-vv-as="del correo electrónico"
                      >
                      <span v-show="vverrors.has('email')" class="help is-danger">{{ vverrors.first('email') }}</span>
                    </div>
                  </div>
                  <div class="column is-12 mb-6">
                    <div class="block has-text-centered block_btn">
                      <button
                        type="submit"
                        class="button button_micro_dark is-medium is-fullwidth"
                        :class="{ 'is-loading' : loading }"
                      >
                        RECUPERAR CONTRASEÑA
                      </button>
                    </div>
                  </div>
                  <div class="column is-12">
                    <p class="paragraph has-text-centered mb-3">
                      Si no necesitas cambiar tu contraseña,<br>
                      puedes <router-link class="emphasis_link" to="/login">Iniciar sesión</router-link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPasswordForm',
  data () {
    return {
      loading: false,
      email: null
    }
  },
  methods: {
    ...mapActions(['userForgot']),
    async forgotPassword () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        await this.userForgot(this.email)
        this.loading = false
        this.email = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  .card_logo {
    max-width: 170px;
  }
  .control.is-fullwidth {
    width: 100%;
  }
  .input {
    border: solid 1px #b5b5b5;
  }
  .input.icon_btn {
    border-top: solid 1px #b5b5b5;
    border-right: none;
    border-left: solid 1px #b5b5b5;
    border-bottom: solid 1px #b5b5b5;
  }
  .button.icon_action {
    border-color: transparent;
    border-top: solid 1px #b5b5b5;
    border-right: solid 1px #b5b5b5;
    border-bottom: solid 1px #b5b5b5;
    background-color: white;
  }
  .button.icon_action:focus {
    box-shadow: none;
  }
  .visibility_icon {
    color: #3b3b3b;
  }

  .block_btn {
    padding: 1rem 10%;
  }

  @media screen and (max-width: 768px) {
    .px-6 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .block_btn {
      padding: 1rem 5%;
    }
  }
</style>
